<script setup lang="ts">
    // ----------------- //
    // ---- IMPORTS ---- //
    // ----------------- //
    import {
        inject,
        watch,
        ref,
        onMounted,
        onBeforeUpdate,
        computed,
        onUnmounted,
        provide
    } from "vue";

    import { InjectionKeys } from 'o365-utils';

    import { IPWAStore } from 'o365.pwa.modules.client.PWAStore.ts';

    import SyncProgress from 'o365.pwa.vue.components.syncDialog.SyncProgress.vue';
    import { SyncDefinition } from 'o365.pwa.modules.client.SyncDefinition.ts';

    import { $t } from 'o365-utils';

    // ------------------- //
    // ---- VARIABLES ---- //
    // ------------------- //
    const { pwaStoreKey } = InjectionKeys;

    const pwaStore = inject<IPWAStore>(pwaStoreKey);

    if (pwaStore === undefined) {
        throw new Error('Failed to find pwaStore.');
    }

    const { state, cancelSync, rerunSync, eventEmitter } = pwaStore;

    const syncDialog = ref<HTMLElement>();
    const syncDialogModal = ref<any>(null);
    const currentSyncDefinition = ref<SyncDefinition | null>(null);

    provide('CurrentSyncDefinition', currentSyncDefinition);

    let itemRefs:Array<HTMLElement> = [];

    const showMore = ref(false);

    // ------------------------- //
    // ---- LIFECYCLE HOOKS ---- //
    // ------------------------- //
    onMounted(() => {
        syncDialogModal.value = new window.bootstrap.Modal(syncDialog.value, {
            backdrop: 'static',
            keyboard: false
        });

        syncDialog.value?.addEventListener('hidden.bs.modal', (_event) => {
            currentSyncDefinition.value = null;
        });
    });

    onUnmounted(() => {
        eventEmitter.off("syncFinished", handleSyncFinished);
        eventEmitter.off("syncStarting", handleSyncStarted);
        eventEmitter.on("appInstalled", handleSyncFinished);
        eventEmitter.on("appInstalling", handleSyncStarted);
    })

    onBeforeUpdate(() => {
        itemRefs = [];
    });

    // -------------------------- //
    // ---- COMPUTED METHODS ---- //
    // -------------------------- //
    const currentSyncProgress = computed(() => {
        return currentSyncDefinition.value?.currentSyncProgress;
    });

    const currentSyncType = computed(() => {
        return currentSyncDefinition.value?.syncType;
    });
    const currentSyncTitle = computed(() => {
        return currentSyncDefinition.value?.title ?? 'Sync';
    });
    
    const currentSyncComplete = computed(() => {
        return currentSyncProgress.value?.complete ?? false;
    });

    const currentSyncDefinitionAutoCloseDialogOnSuccess = computed(() => {
        return currentSyncDefinition.value?.autoCloseDialogOnSuccess ?? false;
    });

    const currentSyncDefinitionRunWithoutUI = computed(() => {
        return currentSyncDefinition.value?.runWithoutUI ?? false;
    });

    const currentSyncProgressHasError = computed(() => {
        return currentSyncProgress.value?.hasError ?? false;
    });

    const currentStepIndex = computed(() => {
        return currentSyncProgress.value?.currentStepIndex ?? 0;
    });

    const currentStep = computed(() => {
        return currentSyncProgress.value?.currentStep ?? 0;
    });

    const numberOfSteps = computed(() => {
        return currentSyncProgress.value?.resourcesProgress?.length ?? 0;
    });

    const currentSyncMarkedAsCanceled = computed(() => {
        return currentSyncProgress.value?.markedAsCanceled ?? false;
    });

    const currentSyncStepsProgress = computed(() => {
        return currentSyncProgress.value?.resourcesProgress ?? [];
    });

    const showCancelButton = computed(() => {
        return !currentSyncMarkedAsCanceled.value && !currentSyncProgressHasError.value;
    });

    const showTryAgainButton = computed(() => {
        return currentSyncMarkedAsCanceled.value || currentSyncProgressHasError.value;
    });

    const disableCancelButton = computed(() => {
        return currentSyncMarkedAsCanceled.value || currentSyncComplete.value;
    });

    const showButtonText = computed(() => {
        return showMore.value ? $t('Show less') : $t('Show more');
    });

    const showCurrentStepProgress = computed(() => {
        return !currentSyncComplete.value || !showMore.value;
    });

    const dialogSubTitle = computed(() => {
        // Sync complete
        if (currentSyncComplete.value) {
            if (currentSyncProgress.value?.hasError) {
                // Sync has error
                return $t('Offline sync in progress');
            } /* else if (currentSyncProgress.value?.hasWarning) {
                // Sync has warning
                return $t('Offline sync in progress');
            } */ else if (currentSyncProgress.value?.markedAsCanceled) {
                // Sync has been cancelled
                return $t('Sync cancelled');
            } else {
                // Sync has completed successfully
                return $t('Sync completed');
            }
        }

        if (currentSyncProgress.value?.markedAsCanceled) {
            // Sync has been marked as cancelled
            return $t('Cancelling sync');
        }

        // Sync in progress
        return $t('Sync in progress');
    });

    const setItemRef = (el: HTMLElement) => {
      if (el) {
        itemRefs.push(el)
      }
    }


    // ------------------ //
    // ---- EVENTS ---- //
    // ------------------ //

    eventEmitter.on("syncFinished", handleSyncFinished);
    eventEmitter.on("syncStarted", handleSyncStarted);
    eventEmitter.on("appInstalled", handleSyncFinished);
    eventEmitter.on("appInstalling", handleSyncStarted);

    function handleSyncFinished() {
        if(!currentSyncDefinitionAutoCloseDialogOnSuccess.value){
            return;
        }

        syncDialogModal.value.hide();
    }
    function handleSyncStarted() {
        currentSyncDefinition.value = state?.currentSync;

        if (currentSyncDefinitionRunWithoutUI.value) {
            return;
        }
        syncDialogModal.value.show();
    }
</script>

<template>
    <teleport to="body">
        <div ref="syncDialog" class="modal o365-sync-dialog" tabindex="-1">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="d-flex flex-column align-item-center text-center w-100">
                            <h5 class="modal-title">{{$t(currentSyncTitle)}}</h5>

                            <p v-if="numberOfSteps > 1">
                                {{$t('Step')}} {{currentStep}} {{$t('of')}} {{numberOfSteps}}
                            </p>

                            <SyncProgress />

                            <div class="d-flex flex-row justify-content-center" style="gap: 10px;">
                                <div class="d-flex flex-row flex-3 justify-content-end">
                                    <button class="btn btn-sm btn-link text-primary" @click="() => cancelSync()"
                                            :disabled="disableCancelButton" v-if="showCancelButton">{{$t('Cancel')}}</button>
                                    <button class="btn btn-sm btn-link text-primary" @click="rerunSync(`${currentSyncType}`)" v-if="showTryAgainButton">{{$t('Try again')}}</button>
                                </div>
                                <div class="d-flex flex-row flex-2 justify-content-center">
                                    <button class="btn btn-sm btn-link text-primary" @click="showMore = !showMore">{{showButtonText}}</button>
                                </div>
                                <div class="d-flex flex-row flex-3 justify-content-start">
                                    <button class="btn btn-sm btn-link text-primary" data-bs-dismiss="modal" :disabled="!currentSyncComplete">{{$t('Done')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="showMore" id="o365-sync-dialog-steps-container" class="modal-body">
                        <div class="d-flex flex-column flex-1 w-100" style="gap: 10px;">
                            <component
                                v-for="(SyncStepProgress, index) in currentSyncStepsProgress"
                                :ref="setItemRef"
                                :is="SyncStepProgress.vueComponentName"
                                :syncStepProgress="SyncStepProgress"
                                :currentStep="currentSyncComplete === false && currentStepIndex === index"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>
